/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useMemo, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import classnames from 'classnames';
import { MainLayout } from '../../templates/MainLayout';
import { YellowHeader } from '../../components/main/YellowHeader';
import { t } from '../../i18n';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../constants/hooks';
import ContactsMap from '../../components/contacts/ContactsMap';
import ContactsCard from '../../components/contacts/ContactsCard';
import { CITIES } from '../../constants/contacts';
import { IHeaderQuery } from '../../queries/headers/types';
import { BuyButton } from '../../components/main/BuyButton';
import DATA from '../../i18n/locales/en';
import ahLogo from '../../images/ah_logo_30.png';

type IProps = PageProps<IHeaderQuery, { locale: string }>;

const ContactsPage: FC<IProps> = ({ data, pageContext: { locale = 'en' } }) => {
  const prefix = usePathPrefix();
  const [tab, setTab] = useState<typeof CITIES[keyof typeof CITIES]>(
    CITIES.KAZAKHSTAN
  );

  const header = useMemo(
    () => data.headers.edges.find((edge) => edge.node),
    [data.headers, locale]
  );

  return (
    <MainLayout
      locale={locale}
      title={DATA.contacts.seo.title}
      description={DATA.contacts.seo.description}
      keywords={DATA.contacts.seo.keywords}
    >
      <YellowHeader header={t('contacts.title')}>
        {header?.node?.html || ''}
      </YellowHeader>

      <div className={classnames('content', styles.wrap)}>
        <ContactsMap tab={tab} setTab={setTab} />

        <div className={styles.grid}>
          <ContactsCard
            icon={`${prefix}/contacts/star.svg`}
            title={t('contacts.kazakhstan')}
            iconAlt="star"
          >
            <address>{header?.node.frontmatter.kazakhstan_address}</address>
          </ContactsCard>

          <ContactsCard
            icon={`${prefix}/contacts/phone.svg`}
            title={
              <a href={`tel://${header?.node.frontmatter.phone}`}>
                {header?.node.frontmatter.phone}
              </a>
            }
            iconAlt="phone"
          >
            <div className={styles.address}>{t('contacts.phone_erevan')}</div>
          </ContactsCard>

          <ContactsCard
            icon={`${prefix}/contacts/email.svg`}
            title={
              <a href={`mailto:${header?.node.frontmatter.email}`}>
                {header?.node.frontmatter.email || 'header.email'}
              </a>
            }
            iconAlt="email"
          >
            <span>{t('contacts.email')}</span>
          </ContactsCard>
        </div>
        <div className={styles.button}>
          <BuyButton>{t('contacts.button')}</BuyButton>
        </div>

        <div className={styles.grid}>
          <div className={styles.astana}>
            <a
              href="https://astanahub.com/"
              target="_blank"
              rel="external noreferrer"
            >
              <div>
                <img src={ahLogo} alt="Astana Hub" />
              </div>
              <div>
                <span>
                  IceRock Development is a member of AstanaHub accelerator
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  query {
    headers: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/contacts/header/header/" } }
    ) {
      ...HeaderFields
    }
  }
`;

export default ContactsPage;
